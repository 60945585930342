import React, { useContext } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import "./App.css";
// import { useMediaQuery } from './shared-functions.js';
import { ThemeContext } from "./Theme.js";
import { Container, Row, Col } from 'react-bootstrap';

// import TopNavbar from "./components/common/TopNavbar.js";
// import Privacy from "./pages/legal/Privacy.js"
// import Terms from "./pages/legal/Terms.js"
// import CookiePolicy from "./components/legal/CookiePolicy.js"
import NotFound from "./NotFound.js";
import Home from "./Home.js";

import { ProvideAuth } from "./use-firebase.js";
import Footer from './components/common/Footer.js';

export default function App() {

  // let isPageWide = useMediaQuery('(min-width: 960px)');
  const { theme } = useContext(ThemeContext);

  document.body.style = theme === 'light-theme' ? 'background: var(--bg-primary-light)' : 'background: var(--bg-primary-dark)';

  return (
    <ProvideAuth>
      <Router>
        <Container className={`App ${theme}`} fluid style={{ marginTop: 0, padding: 0, background: 'linear-gradient(#202124, #202124) no-repeat', height: '100%' }}>
          {/* <TopNavbar /> */}
          <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginTop: 0, marginLeft: 0, marginRight: 0 }}>
            <Col style={{ width: '100%', padding: 10, marginTop: 44, marginLeft: 0 }}>
              <Routes>
                {/* Catch all */}
                <Route path="/notfound" element={<NotFound />} />
                <Route path="/" element={<Home />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Col>
          </Row>
          {/* <Footer /> */}
        </Container>
      </Router>
    </ProvideAuth>
  );
}