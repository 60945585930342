import { Article, BookOpenText, Browser, ChartBar, File, FileText, LinkedinLogo, List, MagnifyingGlass, Microphone, Newspaper, Notebook, QuestionMark, Scroll, Slideshow, YoutubeLogo } from "@phosphor-icons/react";

export const CARDS = [
    {
      name: 'Newsletter',
      plural: 'newsletters',
      bgcolor: '#FF6F61CC',
      textcolor: 'var(--text-primary-light)',
      icon: <Newspaper size={36} color={'#FF6F61CC'} />,
    },
    {
      name: 'Blog Post',
      plural: 'blog posts',
      bgcolor: '#4DB6ACCC',
      textcolor: 'var(--text-primary-light)',
      icon: <Article size={36} color={'#4DB6ACCC'} />,
    },
    {
      name: 'Essay',
      plural: 'essays',
      bgcolor: '#FFB74DCC',
      textcolor: 'var(--text-primary-light)',
      icon: <FileText size={36} color={'#FFB74DCC'} />,
    },
    {
      name: 'Whitepaper',
      plural: 'whitepapers',
      bgcolor: '#9575CDCC',
      textcolor: 'var(--text-primary-light)',
      icon: <File size={36} color={'#9575CDCC'} />,
    },
    {
      name: 'E-book',
      plural: 'e-books',
      bgcolor: '#64B5F6CC',
      textcolor: 'var(--text-primary-light)',
      icon: <BookOpenText size={36} color={'#64B5F6CC'} />,
    },
    {
      name: 'Podcast Script',
      plural: 'podcast scripts',
      bgcolor: '#8D6E63CC',
      textcolor: 'var(--text-primary-light)',
      icon: <Microphone size={36} color={'#FFCA28CC'} />,
    },
    {
      name: 'Youtube Script',
      plural: 'youtube scripts',
      bgcolor: '#29B6F6CC',
      textcolor: 'var(--text-primary-light)',
      icon: <YoutubeLogo size={36} color={'red'} />,
    },
    {
      name: 'Journal Article',
      plural: 'journal articles',
      bgcolor: '#81C784CC',
      textcolor: 'var(--text-primary-light)',
      icon: <Notebook size={36} color={'#81C784CC'} />,
    },
    {
      name: 'Operating Manual',
      plural: 'operating manuals',
      bgcolor: '#F06292CC',
      textcolor: 'var(--text-primary-light)',
      icon: <List size={36} color={'#F06292CC'} />,
    },
    {
      name: 'Market reports',
      plural: 'market reports',
      bgcolor: '#BA68C8CC',
      textcolor: 'var(--text-primary-light)',
      icon: <MagnifyingGlass size={36} color={'#BA68C8CC'} />,
    },
    {
      name: 'LinkedIn Post',
      plural: 'LinkedIn posts',
      bgcolor: '#FF8A65CC',
      textcolor: 'var(--text-primary-light)',
      icon: <LinkedinLogo size={36} color={'lightblue'} />,
    },
    {
      name: 'Case Study',
      plural: 'case studies',
      bgcolor: '#26C6DACC',
      textcolor: 'var(--text-primary-light)',
      icon: <ChartBar size={36} color={'#26C6DACC'} />,
    },
    {
      name: 'Literature Review',
      plural: 'literature reviews',
      bgcolor: '#FFCA28CC',
      textcolor: 'var(--text-primary-light)',
      icon: <Scroll size={36} color={'#FFCA28CC'} />,
    },
    {
      name: 'Pitch Deck',
      plural: 'pitch decks',
      bgcolor: '#AED581CC',
      textcolor: 'var(--text-primary-light)',
      icon: <Slideshow size={36} color={'#66BB6ACC'} />,
    },
    {
      name: 'Lesson Plans',
      plural: 'lesson plans',
      bgcolor: '#AED581CC',
      textcolor: 'var(--text-primary-light)',
      icon: <QuestionMark size={36} color={'#66BB6ACC'} />,
    },
    // {
    //   name: 'Webpage',
    //   bgcolor: '#F44336CC',
    //   textcolor: 'var(--text-primary-light)',
    //   icon: <Browser size={36} color={'var(--text-primary-light)'} />,
    // },
    {
      name: 'Exceptional Content',
      plural: 'exceptional content',
      bgcolor: '#FFCA28CC',
      textcolor: 'var(--text-primary-light)',
      icon: <Scroll size={36} color={'#FFCA28CC'} />,
    },
  ];

  // #, #, #, #, #, #66BB6A, #66BB6A, #D4E157, #FF7043, #AB47BC

  export const BILLING_PLANS = [
  {
    id: "1",
    name: "Starter",
    description: "For getting started",
    priceMonthly: 'Free',
    creditsMonthly: 100,
    benefits: [
      "100 free one-time credits",
      "100MB of total file storage cap",
      "Full access to all AI agents, tools, and skills",
      "Choose any LLM from the newest, major closed and open-sourced models",
      "Invite unlimited team members",
    ],
  },
  {
    id: "2",
    name: "Plus",
    description: "For small teams",
    priceMonthly: '$10',
    creditsMonthly: 1000,
    benefits: [
      "1,000 monthly credits",
      "1GB of total file storage cap",
      "Full access to all AI agents, tools, and skills",
      "Choose any LLM from the newest, major closed and open-sourced models",
      "Invite unlimited team members",
      "Cancel anytime",
    ],
  },
  {
    id: "3",
    name: "Pro",
    description: "For growing teams",
    priceMonthly: '$100',
    creditsMonthly: 12500,
    benefits: [
      "12,500 monthly credits",
      "10GB of total file storage cap",
      "Full access to all AI agents, tools, and skills",
      "Choose any LLM from the newest, major closed and open-sourced models",
      "Invite unlimited team members",
      "Cancel anytime",
    ],
  },
  {
    id: "4",
    name: "Enterprise",
    description: "For large or specialized teams",
    priceMonthly: 'Custom',
    creditsMonthly: 1500000,
    benefits: [
      "Full access to all AI agents, tools, and skills",
      "Choose any LLM from the newest, major closed and open-sourced models",
      "Custom LLM options",
      "Private, or on-prem installation options",
      "Invite unlimited team members",
      "Cancel anytime",
    ],
  },
];
