import React from "react";
// import { useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

export default function Footer() {

  // let location = useLocation()

  return (
    <section className="footer-text" style={{ width: '100%', backgroundColor: 'transparent', color: 'var(--text-secondary)', height: 100, padding: 40, textAlign: 'center', marginTop: 20, marginBottom: 40 }}>
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <Col style={{ textAlign: 'center' }}>
          <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
            {/* <p className="muted small" style={{ marginRight: 10 }}>hello@terastack.ai</p> */}
            <p className="small" style={{ marginRight: 10 }}><a href="https://docs.google.com/document/d/1JpyeDS_5yTMN5c4ssaoZAzrYziZjqPYKUVzOrRICpQ4/edit?usp=sharing" target="_blank" rel="noreferrer">Privacy Policy</a></p>
            <p className="small" style={{ marginRight: 10 }}><a href="https://docs.google.com/document/d/1V_SgZmg49dZ7dwqm7JdTP8_4T4Bkvt9ZQKEVSHSkpig/edit?usp=sharing" target="_blank" rel="noreferrer">Terms of service</a></p>
          </Row>
          <p className="tiny" style={{ marginTop: 5 }}>Copyright 2024, Molecule Climate Inc.</p>
        </Col>
      </Row>
    </section>
  )

  // return (
  //   <section className="footer-text" style={{ width: '100%', backgroundColor: 'var(--bg-primary)', color: 'var(--text-primary)', paddingTop: 40, paddingBottom: 40 }}>
  //     <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: '10%', marginRight: '10%' }}>
  //       <Col xs={6} sm={6} md={4} lg={3} xl={3} style={{ paddingTop: 20 }}>
  //         <h6>PRODUCT</h6>
  //         <p style={{ marginTop: 20 }}><a href="/">Home</a></p>
  //         { false && <p style={{ marginTop: -10 }}><a href="/how-it-works">How it works</a></p> }
  //         { false && <p style={{ marginTop: -10 }}><a href="/faqs">FAQs</a></p> }
  //         <p style={{ marginTop: -10 }}><a href="https://calendly.com/fundamental" target="_blank">Schedule a demo</a></p>
  //       </Col>
  //       <Col xs={6} sm={6} md={4} lg={3} xl={3} style={{ paddingTop: 20 }}>
  //         <h6>LEGAL</h6>
  //         <p style={{ marginTop: 20 }}><a href="/">Privacy Policy</a></p>
  //         <p style={{ marginTop: -10 }}><a href="/">Terms of Service</a></p>
  //         <p style={{ marginTop: -10 }}><a href="/">Cookie Policy</a></p>
  //       </Col>
  //       <Col xs={6} sm={6} md={4} lg={3} xl={3} style={{ paddingTop: 20 }}>
  //         <h6>Contact</h6>
  //         <p style={{ marginTop: 20 }}><a href="mailto:hello@moleculecommerce.com">hello@moleculecommerce.com</a></p>
  //       </Col>
  //     </Row>
  //     <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 60, textAlign: 'center' }}>
  //       <p className="small text-muted">Built by a 100% remote team. Copyright 2024, Terastack Inc.</p>
  //     </Row>
  //   </section>
  // )

}


// <Col style={{ textAlign: 'right' }}>
//   <p className="muted small">
//     Twitter&nbsp;&nbsp;Discord&nbsp;&nbsp;Guide
//   </p>
// </Col>
